import * as React from 'react';
import styled from 'styled-components';
import { Hero } from '../../../ui/hero/Index';
import { Heading } from '../../../ui/text/Heading';

import { animated, useSpring } from 'react-spring';
import { Paragraph } from '../../../ui/text/Paragraph';
import { EmailOldIcon } from '../../../ui/icons/Email';
import { Link, useLocation } from 'react-router-dom';
import { Pagination } from '../../../ui/pagination/Pagination';

import { Logo } from '../../../ui/logo/office/Index';
import { Form, FormRow } from '../../../ui/form/Form';
import { Button } from '../../../ui/button/Button';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { ButtonLink } from '../../../ui/link/ButtonLink';
import { Input } from '../../../ui/form/Input';
import { getDiggerData } from '../../../../utils/tracking/digger';
import { createGTMEvent } from '../../../../utils/tracking/gtm';
import {
  createLeadMutation,
  SAVE_DIGGER_ID_MUTATION
} from '../../../../../client/__graphql__/mutations';
import { useMutation } from '@apollo/client';
import { SiteContext } from '../../../../utils/context/SiteContext';
import { useForm } from '../../../../utils/hooks/useForm';
import { FormError } from '../../../ui/form/FormError';
import { Seo } from '../../../../utils/seo/Index';

const KunderVelgerOssKampanje: React.FC = () => {
  const location = useLocation();
  const { digger }: any = React.useContext(SiteContext);

  const animatedContent = useSpring({
    delay: 500,
    from: { opacity: 0 },
    to: { opacity: 1 }
  });

  const animatedOtherContent = useSpring({
    delay: 700,
    from: { opacity: 0 },
    to: { opacity: 1 }
  });

  const [posted, setPosted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const VALUATION_MUTATION = createLeadMutation({
    valuation: {
      response: `{
        success
      }`
    }
  });

  const [lead, { error: postError }] = useMutation<any, any>(
    VALUATION_MUTATION,
    {
      onError: ({ graphQLErrors, networkError }) => {
        setLoading(false);
        console.log(graphQLErrors, networkError);
      },
      onCompleted: ({ lead }) => {
        setLoading(false);

        if (lead.valuation?.success) {
          setPosted(true);
          // GTM Tracking

          createGTMEvent({
            event: 'pmFormSubmission',
            gaEvent: 'Verdivurdering Trondheim Kampanje',
            gaCategory: 'Form',
            gaAction: 'Submit'
          });
        }
      }
    }
  );

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'address',
        placeholder: 'Eks. Adresseveien 23',
        value: '',
        label: 'Adresse'
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields = getFields(fields);
      setLoading(true);
      lead({
        variables: {
          input: {
            ...preparedFields,
            identifier: 'hans_christian_espenes',
            referer: location.pathname,
            leadType: 'VERDIVURDERING',
            pageType: 'MEGLER'
          }
        }
      });
    }
  });

  return (
    <Page>
      <Seo
        title="Kjøp og salg av eiendommer i det øvre prissegmentet handler om tillit - PrivatMegleren"
        description="Det handler om erfaring, profesjonalitet, nettverk og leveranser av høy kvalitet. Vi er stolte av å være den foretrukne megleren i høyere prissegmenter"
        image="https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/trondheim_kampanje.jpg"
        url={location.pathname}
      />
      <Hero
        size="cover"
        height="650"
        imageUrl="https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/trondheim_kampanje.jpg"
        opacity={1}
        alt="bilde"
      >
        <HeroContent style={animatedContent}>
          <Heading tag="h1" center={true}>
            Kjøp og salg av eiendommer i det øvre prissegmentet handler om
            tillit
          </Heading>
          <Paragraph center={true}>
            Det handler om erfaring, profesjonalitet, nettverk og leveranser av
            høy kvalitet. Vi er stolte av å være den foretrukne megleren i
            høyere prissegmenter*. Det bekrefter at vi leverer en
            kundeopplevelse og salgsresultater som både innfrir og overgår
            forventningene. Hele 9 av 10 som velger å bruke oss, gjør det
            faktisk på anbefaling fra tidligere kunder.
          </Paragraph>
          <Paragraph center={true}>
            <b>
              * PrivatMegleren selger flest eiendommer i Norge med verdi over 10
              mill.
            </b>
          </Paragraph>
        </HeroContent>
      </Hero>
      <StyledColumnsSection style={animatedOtherContent}>
        <div className="section-container">
          <SectionColumn
            title="Egen fototjeneste"
            text="Det er viktig å formidle hele eiendommens verdi, og en stor del av den jobben gjøres av gode, engasjerende og gjennomført profesjonelle bilder."
          />
          <SectionColumn
            title="Egen skribent"
            text="Vi har en egen tekstforfatter på laget, som gjennom samtale og intervjuer setter seg grundig inn i hele verdien av din eiendom."
          />
          <SectionColumn
            title="Digitale løsninger"
            text="Eiendomssalget starter på nett. Vår unike annonseteknologi bidrar til at din eiendom legges merke til, uansett temperatur i markedet."
          />
        </div>
      </StyledColumnsSection>
      <EvidenceSection style={animatedOtherContent}>
        <div className="section-container">
          <EvidenceCard>
            <Heading tag="h2" center={true}>
              Engasjerte meglere
            </Heading>
            <EvidenceImage src="https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/hans_christian.jpg" />
            <Paragraph center={true}>
              Hans Christian Espenes er salgsleder, eiendomsmegler og partner i
              PrivatMegleren Trondheim. De siste årene har mange av eiendommene
              i den øvre prisklassen i Trondheim, vært solgt av Hans Christian
              Espenes
            </Paragraph>
            <EvidenceCardInfo>
              <a href="tel:94001660" className="phone">
                94 00 16 60
              </a>
              <Link to={`/megler/hans_christian_espenes/kontakt`}>
                <EmailOldIcon />
              </Link>
            </EvidenceCardInfo>
          </EvidenceCard>
          <EvidenceCard>
            <Heading tag="h2" center={true}>
              Forvaltning av verdier
            </Heading>
            <SoldCarousel />
          </EvidenceCard>
        </div>
      </EvidenceSection>
      <Section style={animatedOtherContent}>
        <div className="section-container-form">
          <Heading tag="h1" center={true}>
            Vurderer du å selge eiendom i det øvre prissegmentet i Trondheim?
          </Heading>
          <Paragraph center={true}>
            Ta kontakt hvis du vil ha en uforpliktende prat om din eiendom og de
            mulighetene den har i de riktige markedene. Fyll inn dette skjemaet,
            og Hans Christian vil kontakte deg. Eventuelt bruk direkte nummer og
            epost som du finner over.
          </Paragraph>
          {posted ? (
            <div style={{ marginTop: '100px' }}>
              <Heading tag="h2" center={true}>
                Takk for din henvendelse
              </Heading>
            </div>
          ) : (
            <Form onSubmit={handleSubmit} noValidate>
              {postError &&
                postError.graphQLErrors.map((error) => (
                  <FormError message={error.message} />
                ))}
              {fields.map((item: any, index: number) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Input
                      type={item.type}
                      name={item.name}
                      label={item.label}
                      labelAfter={item.labelAfter}
                      placeholder={item.placeholder}
                      value={item.value}
                      onChange={handleChange}
                      error={item.error}
                    />
                  </FormRow>
                );
              })}
              <ButtonGroup>
                <Button
                  type="primary"
                  colorTheme="gold"
                  disabled={loading}
                  loading={loading}
                >
                  Send
                </Button>
                <ButtonLink
                  as="a"
                  href="https://privatmegleren.no/personvern"
                  className="privacy-link-mobile"
                >
                  Personvernpolicy
                </ButtonLink>
              </ButtonGroup>
            </Form>
          )}
        </div>
      </Section>
      <Section style={animatedOtherContent}>
        <div className="section-container">
          <Logo name="Trondheim" alt="bilde av logo" />
          <p>Kongens gate 18, 701 Trondheim</p>
          <Paragraph center={true}>
            PrivatMegleren Trondheim er samlokalisert med Nordea i sentrum av
            Trondheim. Kontoret ble etablert våren 2010, basert på en strategi
            om å rekrutere de beste meglerne i det lokale markedsområdet og la
            dem gjennomføre en mer omfattende meglertjeneste enn konkurrentene.
          </Paragraph>
        </div>
      </Section>
    </Page>
  );
};

export default KunderVelgerOssKampanje;

const SectionColumn: React.FC<{ title: string; text: string }> = ({
  title,
  text
}) => {
  return (
    <StyledColumnSection>
      <h3>{title}</h3>
      <p>{text}</p>
    </StyledColumnSection>
  );
};

const StyledColumnSection = styled.div`
  width: 100%;
`;

interface SoldPropertyItem {
  image: string;
  imageText?: string;
  text: string;
  link?: string;
  sguid?: number;
}

const SoldCarousel: React.FC = React.memo(() => {
  const [page, setPage] = React.useState(0);
  const [current, setCurrent] = React.useState<SoldPropertyItem>();
  const [items] = React.useState<Array<SoldPropertyItem>>([
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/114220306_1.jpg',
      imageText: 'Bergheimsvegen 28',
      sguid: 114220306,
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/114220306_2.jpg',
      imageText: 'Solgt for kr <span class="red">13 700 000,-</span>',
      sguid: 114220306,
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/114220236_1.jpg',
      imageText: 'Sigurd Slembes veg 2',
      sguid: 114220236,
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/114220236_2.jpg',
      imageText: 'Solgt for kr <span class="red">11 950 000,-</span>',
      sguid: 114220236,
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/114220257_1.jpg',
      imageText: 'Magnus Blindes veg 10 A',
      sguid: 114220257,
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/114220257_2.jpg',
      imageText: 'Solgt for kr <span class="red">10 800 000,-</span>',
      sguid: 114220257,
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/114220182_1.jpg',
      imageText: 'Sigurd Bergs alle 4',
      sguid: 114220182,
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/114220182_2.jpg',
      imageText: 'Solgt for kr <span class="red">14 650 000,-</span>',
      sguid: 114220182,
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/114210158_1.jpg',
      imageText: 'Havsteinbakken 1',
      sguid: 114210158,
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/114210158_2.jpg',
      imageText: 'Solgt for kr <span class="red">14 200 000,-</span>',
      sguid: 114210158,
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/20.12.21/Bolig_An-Magritts vei 12.jpg',
      imageText: 'An-Magrits vei 12',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/20.12.21/Bolig_An-Magritts vei 12 -1.jpg',
      imageText: 'Solgt for kr <span class="red">10 800 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/20.12.21/Bolig_Bjørnsons gate 8.jpg',
      imageText: 'Bjørnsonsgate 8',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/20.12.21/Bolig_Bjørnsons gate 8 -1.jpg',
      imageText: 'Solgt for kr <span class="red">10 000 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/20.12.21/Bolig_Catharine Lysholms veg 3 -1.jpg',
      imageText: 'Catharine Lysholms veg 3',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/20.12.21/Bolig_Catharine Lysholms veg 3 -2.jpg',
      imageText: 'Solgt for kr <span class="red">11 000 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/20.12.21/Bolig_Nidareid 1 -1.jpg',
      imageText: 'Nidareid 1',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/20.12.21/Bolig_Nidareid 1 -1.jpg',
      imageText: 'Solgt for kr <span class="red">9 000 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/20.12.21/Bolig_Riiser-Larsens vei 5 -1.jpg',
      imageText: 'Riiser-Larsens vei 5',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/20.12.21/Bolig_Riiser-Larsens vei 5 -2.jpg',
      imageText: 'Solgt for kr <span class="red">12 300 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/20.12.21/Bolig_Arkitekt Christies gate 4 C -1.jpg',
      imageText: 'Arkitekt Christies gate 4 C',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/20.12.21/Bolig_Arkitekt Christies gate 4 C -2.jpg',
      imageText: 'Solgt for kr <span class="red">9 300 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_EllenLiesVei2_02.jpg',
      imageText: 'Ellen Lies veg 1',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_EllenLiesVei_01.jpg',
      imageText: 'Solgt for kr <span class="red">12 100 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_EventyrVegen51_01.jpg',
      imageText: 'Eventyrveien 51',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_EventyrVegen51_01.jpg',
      imageText: 'Solgt for kr <span class="red">17 000 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_VegMesterStien27D_02.jpg',
      imageText: 'Vegmesterstien 27D',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_VegMesterStien27D_01.jpg',
      imageText: 'Solgt for kr <span class="red">13 300 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_OddHusbysVeg_01.jpg',
      imageText: 'Odd Husbys veg 32a',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_OddHusbysVeg_02.jpg',
      imageText: 'Solgt for kr <span class="red">14 200 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_Karisvingen_01.jpg',
      imageText: 'Karisvingen 24b',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_Karisvingen_02.jpg',
      imageText: 'Solgt for kr <span class="red">12 400 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_OddSorlisVeg_01.jpg',
      imageText: 'Odd Sørlis veg 27',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_OddSorlisVeg_02.jpg',
      imageText: 'Solgt for kr <span class="red">12 150 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_LoholtAlle_01.jpg',
      imageText: 'Loholt Allè 42e',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_LoholtAlle_02.jpg',
      imageText: 'Solgt for kr <span class="red">13 700 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_GeneralVonHovensVei_01.jpg',
      imageText: 'General von Hovens 2b',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_GeneralVonHovensVei_02.jpg',
      imageText: 'Solgt for kr <span class="red">11 400 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_Jonsvannsveien_01.jpg',
      imageText: 'Jonsvannsveien 421',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_Jonsvannsveien_02.jpg',
      imageText: 'Solgt for kr <span class="red">10 650 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_BennechesVeg_01.jpg',
      imageText: 'Benneches veg 6',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_BennechesVeg_02.jpg',
      imageText: 'Solgt for kr <span class="red">12 500 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_Breisynvegen_01.jpg',
      imageText: 'Breisynvegen 8',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_Breisynvegen_02.jpg',
      imageText: 'Solgt for kr <span class="red">11 250 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },

    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_Mellomlia_01.jpg',
      imageText: 'Mellomila 55f',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    },
    {
      image:
        'https://cdn.reeltime.no/pm_assets/kampanjer/trondheim/bolig/Bolig_Mellomlia_02.jpg',
      imageText:
        'Solgt «off market» for kr <span class="red">11 800 000,-</span>',
      text: 'Når det handler om det mest dyrebare du eier, skal du forvente profesjonell bistand. Her er noen eksempler på eiendommer i den høye prissegmentet i Trondheim som er solgt av oss det siste året.'
    }
  ]);

  const [props, set] = useSpring(() => ({ opacity: 0 }));

  React.useEffect(() => {
    set({ opacity: 0, immediate: true });
    setCurrent(items[page]);
    set({ delay: 100, opacity: 1 });
  }, [page]);

  React.useEffect(() => {
    set({ opacity: 1 });
  }, []);

  return (
    <StyledSoldCarouselWrapper>
      <CarouselSection style={props}>
        <CarouselImageWrapper>
          {current?.imageText ? (
            <CarouselImageOverlay>
              <div
                className="overlay-text"
                dangerouslySetInnerHTML={{ __html: current?.imageText }}
              />
            </CarouselImageOverlay>
          ) : null}
          <img src={current?.image} alt="bilde" />
        </CarouselImageWrapper>
        <p>{current?.text}</p>
      </CarouselSection>
      <Pagination
        center={true}
        currentPage={page}
        maxPages={2}
        total={items.length}
        pageRangeDisplayed={1}
        itemsPerPage={1}
        pageOnChange={({ selected }) => {
          setPage(selected);
        }}
      />
    </StyledSoldCarouselWrapper>
  );
});

const StyledSoldCarouselWrapper = styled.div`
  display: block;
`;

const CarouselSection = styled(animated.div)`
  display: block;
  text-align: center;
`;

const CarouselImageOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .overlay-text {
    font-family: 'DomaineDisp', sans-serif;
    font-weight: 300;
    font-size: 18px;
    display: inline-block;
    padding: 5px;
    background: #23030a;
    color: ${({ theme }) => theme.colors.primary};

    .red {
      color: #ff0021;
    }

    @media all and (max-width: 500px) {
      width: 90%;
    }
  }
`;

const CarouselImageWrapper = styled.div`
  position: relative;
  display: block;

  img {
    width: 100%;
  }
`;

const HeroContent = styled(animated.div)`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 960px;
  padding: 0 50px;

  h1 {
    margin-bottom: 0;
    width: 100%;
    word-break: break-word;
  }

  h2 {
    width: 100%;
    word-break: break-word;
  }
`;

const StyledColumnsSection = styled(animated.section)`
  display: block;
  padding: 40px 20px;
  width: 100%;
  background: rgb(0, 0, 0);
  text-align: center;

  h3 {
    margin-bottom: 50px;
    &:after {
      content: '';
      display: block;
      position: relative;
      top: 25px;
      margin: 0 auto;
      width: 40px;
      height: 4px;
      background: ${({ theme }) => theme.colors.coral};
    }
  }

  .section-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 25px;
    max-width: 960px;
    width: 100%;
    margin: 0 auto;

    @media all and (max-width: 960px) {
      grid-template-columns: 1fr;
      grid-column-gap: 25px;
      width: 100%;
      padding: 0 30px;
    }
  }
`;

const EvidenceSection = styled(animated.section)`
  display: flex;
  width: 100%;
  padding: 25px 30px;
  background: #23030a;

  .section-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    max-width: 960px;
    width: 100%;
    margin: 0 auto;

    @media all and (max-width: 960px) {
      grid-template-columns: 1fr;
      grid-column-gap: 25px;
      grid-row-gap: 25px;
      width: 100%;
    }
  }
`;

const Section = styled(animated.section)`
  display: block;
  width: 100%;
  padding: 60px 30px;

  .section-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
  }

  .section-container-form {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
  }
`;

const EvidenceImage = styled.img`
  width: 100%;
  margin: 0;
`;

const EvidenceCard = styled.div`
  display: flex;
  flex-flow: column;
  background: #1a0307;
  padding: 20px;
`;

const EvidenceCardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;

  .phone {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Page = styled.section`
  margin-top: 66px;
  display: block;
  width: 100%;
  background: rgb(0, 0, 0);
`;
